import Head from "next/head";
import dynamic from "next/dynamic";
import {handleLogout} from "components/utils"; import React from "react";

export default function Home() {
    const NoSSRExchangeViewComponent = dynamic(
        () => {
            return import("../components/landing/home");
        },
        {ssr: false}
    );
    return (
        <div>
            <Head>
                <title>QMe - Hospital Management and Information System</title>
                <link rel="canonical" href="https://www.qme.co.in/" />
                <meta property="og:image" content="/og.png"/>
                <meta property="og:title" content="Effortless Patient Management & Token-Based Appointments | QME"/>
                <meta property="og:description" content="Experience efficient stock control, innovative token-based scheduling, and smart queues for unparalleled patient experiences at QME. Read for more!"/>
                <meta name="twitter:image" content="/og.png"/>
                <meta name="robots" content="index, follow"/>
                <meta name="description" content="QMe - Hospital Management and Information System"/>
                <meta name="keywords" content="Hospital Management Solution, Information System for Hospitals, HMIS Software,
                Digital Prescription System, Token-Based Appointments, Outpatient Department Management, Inpatient Department
                Management, Inventory Control Software, Stock Management System, Automatic Billing Solution,
                Electronic Approvals for Healthcare, Healthcare Management Software, Integrated Hospital Management, Electronic Medical Records (EMR),
                Patient Data Management, Appointment Scheduling System, Pharmacy Inventory Management,
                Medical Billing and Coding, Clinical Data Management, Revenue Cycle Management, Patient Registration System, E-Prescribing System,
                Healthcare Analytics, Workflow Automation in Hospitals, Healthcare Information Technology (HIT)"/>
                <link href="https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900|Ubuntu:400,500,700"
                      rel="stylesheet"/>
                <script
                    key="schema-jsonld"
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "name": "QMe - Hospital and Clinic Management",
                            "image": "https://www.qme.co.in/logo.svg",
                            "description": "Effortless patient management, efficient stock and inventory control, unparalleled patient experience through innovative token-based appointments and smart queues and much more.",
                            "url": "https://www.qme.co.in/",
                            "telephone": "+919971513514",
                            "emailid": "contact@qme.co.in",
                            "sameAs": ["https://www.facebook.com/qmehmis", "https://www.instagram.com/qmehmis", "https://www.youtube.com/@qmehmis"]
                        }, null, "\t") }}
                />

            </Head>
            <NoSSRExchangeViewComponent/>
        </div>
    )
}
